<template>
  <b-card>
    <b-row class="justify-content-between">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Provider Name or MSISDN"
            v-model="filter.search"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :interviewData="interviewData"
      :is-loading="isLoading"
      :get-data="getData"
      :approve-item="approveItem"
      :current-page="currentPage"
    />
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Table from "@/components/interview/Table.vue";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    vSelect,
    flatPickr,
    // Modal,
  },
  //   directives: {
  //     "b-modal": VBModal,
  //     Ripple,
  //   },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      interviewData: {},
      // status: [
      //   { value: "active", name: "Aktif" },
      //   { value: "inactive", name: "Tidak Aktif" },
      // ],
      filter: {
        perPage: 10,
      },
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      this.filter.page = page;
      this.$http
        .get("/admin/interview-schedule", {
          params: this.filter,
        })
        .then((response) => {
          this.result = response.data.meta;
          this.interviewData = response.data.data;
          this.currentPage = response.data.meta.page;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    approveItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to approve this Provider?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .post(`/admin/interview-schedule/verified/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "User successfully approved",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
